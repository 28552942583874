import React, { useState, useEffect, useRef } from 'react'
import MenuJson from './AnimatedChart.json'
import useScroll from 'src/hooks/window/useScroll'

import './AnimatedChart.scss'

type AnimatedChartProps = {
  id: string;
  grid?: string;
  contentData?: any;
}

type ContentDataProps = {
  name: string;
  colorName: string;
  percentage: string;
  colorPercentage?: string;
  color: string;
  height: string;
  border: string;
}

const AnimatedChart = ({ id, grid, contentData = MenuJson }: AnimatedChartProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const scrollPositionY = useScroll(100)
  const data = contentData.find(item => item.id === id)
  const [ animatedClass, setAnimatedClass ] = useState('')

  useEffect(() => {
    const divPosition = divRef.current && divRef.current.getBoundingClientRect().y
    const divVisible = divPosition && divPosition * 1.1 <= window.innerHeight
    if (divVisible) setAnimatedClass('animated-chart')
  }, [ scrollPositionY ])

  return (
    <div ref={divRef} className={`d-flex graph-container height-animate ${grid} ${animatedClass}`}>
      {data?.content.map((item: ContentDataProps) => (
        <div key={item.name} className='animate-content text-center align-items-end'>
          <div style={{ height: item.height }} className='wrapper-chart d-flex justify-content-end flex-column'>
            <span className={`text-gray--600 fs-12 fs-lg-14 fw-900 mb-2 text-center percentage-product ${item.colorPercentage ? 'percentage--accent' : ''} ${item.border !== '' ? 'px-lg-1' : ''}`} style={{ background: item.border, color: item.colorPercentage }} dangerouslySetInnerHTML={{ __html: item.percentage }} />
            <div className='chart chart--animate mx-auto' style={{ background: item.color }} />
          </div>
          <span className='mt-3 text-chart' style={{ color: item.colorName }}>{item.name}</span>
        </div>
      ))}
    </div>
  )
}

export default AnimatedChart
