import React, { useState, ChangeEvent } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { getParameterByName, getCookie } from 'src/shared/helpers'
import AcceptTerms from 'src/components/AcceptTerms'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import EnterpriseSubmittedMessage from 'src/components/UI/Forms/Submit/Messages/EnterpriseSubmittedMessage'
import { FormStyle } from './style'
import selectInput from './assets/data/selectInput.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { apiForm } from 'src/config/api/axiosRequest'

interface IFormValues {
  areaResponsavel: string;
  qtdFuncionarios: string;
  acceptTerms: boolean;
  firstName: string;
  lastName: string;
  company: string;
  mobile: string;
  email: string;
  cargo: string;
}

interface IOptionSelectType {
  value: string;
  label: string;
}

interface ISelectInputType {
  [param: string]: IOptionSelectType[];
}

const Form = () => {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ email, setEmail ] = useState('')
  const [ accept, setAccept ] = useState(false)
  const gclid = getParameterByName('gclid', undefined) || ''
  const gaid = getCookie('_ga')
  const [ sendDatalayerEvent ] = useDataLayer()

  const selectInputJSON: ISelectInputType = selectInput
  const sucessMsg = {
    success: 'Recebemos a sua solicitação!',
    success_text: 'Em breve, você receberá contato dos nossos consultores.',
    previous_page: '',
  }
  const sendForm = async (data: IFormValues) => {
    setError(false)
    setLoading(true)

    const { firstName, email, areaResponsavel, cargo, company, mobile, qtdFuncionarios }: IFormValues = data
    const formData = {
      acceptTerms: true,
      promotionReceivables: true,
      areaResponsavel: areaResponsavel,
      cargo: cargo,
      company: company.trim(),
      qtdFuncionarios: qtdFuncionarios,
      mobile: mobile.replace(/[^\w\s]/gi, '').replace(' ', ''),
      phone: mobile.replace(/[^\w\s]/gi, '').replace(' ', ''),
      lastName: firstName.split(/(?<=^\S+)\s/)[1],
      gclid: gclid,
      gaClientId: gaid,
      firstName: firstName.trim().split(' ')[0],
      email: email.trim(),
      }

    try {
      await apiForm.post(`${URLS.CONTACT_FORM_POST_V5}/lead-consignado-privado-site`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setSent(true)
      sendDatalayerEvent({
        section: 'dobra_06',
        section_name: 'Seja uma empresa conveniada',
        element_action: 'submit',
        element_name: 'Solicitar contato',
        step: 'success',
      })
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  if (sent) {
    return (
      <section className='form-section form-section--' id='bottom-form'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-sm-12 col-lg-8 form-section--background'>
              <div className='custom-form'>
                <EnterpriseSubmittedMessage message={sucessMsg} />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className='form-section form-section--' id='bottom-form'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-lg-8 form-section--background'>
            <FormStyle
              onSubmit={handleSubmit(sendForm)}
              className='custom-form'
            >
              <div className='row'>
                <div className='col-12 pb-3'>
                  <label htmlFor='company' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Nome da Empresa</label>
                  <input
                    className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                    ref={register({
                        required: 'Nome obrigatório',
                      })}
                    name='company'
                    id='company'
                    type='text'
                    maxLength={80}
                    placeholder='Digite o nome da empresa'
                  />
                  { errors.company && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.company.message}</p> }
                </div>
                <div className='col-12 pb-3'>
                  <label htmlFor='firstName' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Nome do Responsável</label>
                  <input
                    className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                    ref={register({
                        required: 'Nome completo obrigatório',
                        validate: {
                          isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                        },
                      })}
                    name='firstName'
                    id='firstName'
                    type='text'
                    maxLength={80}
                    placeholder='Digite o nome completo'
                  />
                  { errors.firstName && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.firstName.message}</p> }
                </div>
                <div className='col-12 col-md-6 pb-3'>
                  <div className='col-12 d-flex flex-column mt-2 px-0'>
                    <label htmlFor='cargo' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Cargo do Responsável</label>
                    <span className='select mb-0 inter-forms--select inter-forms--box '>
                      <select
                        ref={register({
                          required: 'Cargo do Responsável não deve ser vazio',
                        })}
                        name='cargo'
                        id='cargo'
                      >
                        <option value='' disabled selected>Escolha uma opção</option>
                        {selectInputJSON['responsible-role'].map((data: IOptionSelectType) => (
                          <option key={data.value} value={data.value}>{data.label}</option>
                          ))}
                      </select>
                    </span>
                    { errors.cargo && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.cargo.message}</p> }
                  </div>
                </div>
                <div className='col-12 col-md-6 pb-3'>
                  <div className='col-12 d-flex flex-column mt-2 px-0'>
                    <label htmlFor='areaResponsavel' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Área do Responsável</label>
                    <span className='select mb-0 inter-forms--select inter-forms--box '>
                      <select
                        ref={register({
                          required: 'Área do Responsável não deve ser vazio',
                        })}
                        name='areaResponsavel'
                        id='areaResponsavel'
                      >
                        <option value='' disabled selected>Escolha uma opção</option>
                        {selectInputJSON['responsible-sector'].map((data: IOptionSelectType) => (
                          <option key={data.value} value={data.value}>{data.label}</option>
                          ))}
                      </select>
                    </span>
                    { errors.areaResponsavel && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.areaResponsavel.message}</p> }
                  </div>
                </div>
                <div className='col-12  pb-3'>
                  <div className='col-12 d-flex flex-column mt-2 px-0'>
                    <label htmlFor='email' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>E-mail do Responsável</label>
                    <input
                      className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                      ref={register({
                          required: 'Informe um e-mail válido',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'E-mail inválido',
                          },
                        })}
                      name='email'
                      id='email'
                      value={email}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
                      maxLength={80}
                      type='text'
                      placeholder='Digite o e-mail completo'
                    />
                    { errors.email && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.email.message}</p> }
                  </div>
                </div>
                <div className='col-12 pb-3'>
                  <div className='col-12 d-flex flex-column mt-2 px-0'>
                    <label htmlFor='mobile' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Telefone</label>
                    <input
                      className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                      ref={register({
                          required: 'Telefone obrigatório',
                          pattern: {
                            value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                            message: 'Telefone inválido',
                          },
                        })}
                      name='mobile'
                      id='mobile'
                      type='tel'
                      placeholder='Digite seu telefone'
                      maxLength={15}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('mobile', Masks.MaskPHONE(event.currentTarget.value))}
                    />
                    { errors.mobile && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.mobile.message}</p> }
                  </div>
                </div>
                <div className='col-12 pb-3'>
                  <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                    <label htmlFor='qtdFuncionarios' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Quantidade de Funcionários</label>
                    <span className='select mb-0 inter-forms--select inter-forms--box '>
                      <select
                        ref={register({
                          required: 'Quantidade de Funcionários não deve ser vazio',
                        })}
                        name='qtdFuncionarios'
                        id='qtdFuncionarios'
                      >
                        <option value='' disabled selected>Escolha uma opção</option>
                        {selectInputJSON['total-employees'].map((data: IOptionSelectType) => (
                          <option key={data.value} value={data.value}>{data.label}</option>
                          ))}
                      </select>
                    </span>
                    { errors.qtdFuncionarios && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.qtdFuncionarios.message}</p> }
                  </div>
                </div>
                <div className='pb-1 col-12'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='acceptTerms' />
                </div>
              </div>
              <div>
                <button
                  type='submit' className={`btn my-3 btn--turquoise btn--block ${loading ? 'loading' : ''}`}
                  title='Enviar' disabled={!accept || loading}
                >
                  {loading ? 'ENVIANDO...' : 'SOLICITAR CONTATO'}
                </button>
              </div>
              {error && (
                <div className='d-flex align-items-center'>
                  <OrangeIcon size='MD' color='#FF2A44' icon='canceled' />
                  <span className='pl-2 text-white font-italic'>Ops! Ocorreu um erro ao enviar o contato. Tente novamente!</span>
                </div>)}
            </FormStyle>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Form
