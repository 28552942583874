import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import { SubHeader, Section, SubHeaderVantagem } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Icon from 'src/components/UI/MarkdownIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const Vantagens = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <SubHeader className='fs-26 fs-lg-40 text-center text-white mb-3 pb-1'>Veja as vantagens</SubHeader>
        <div className='row'>
          <div className='col-12 col-lg-6 mb-3 mb-lg-0'>
            <SubHeaderVantagem className='fs-17 fs-lg-36 d-flex align-items-center mb-4 text-white'>
              <span className='d-none d-lg-inline'>
                <OrangeIcon icon='market' color='#FFFFFF' size='LG' />
              </span>
              <span className='d-lg-none'>
                <OrangeIcon icon='market' color='#FFFFFF' size='MD' />
              </span>
              Pra Empresa
            </SubHeaderVantagem>
            <ul className='list-style-none p-0 my-4 text-white'>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Oferecer apoio ao colaborador sobre Educação Financeira.
              </li>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Benefício que pode fazer parte do pacote, totalmente sem custo.
              </li>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Controle total sobre a concessão do benefício.
              </li>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Gestão completa pela internet, com sistema didático.
              </li>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Atendimento eficiente na Central de Atendimento.
              </li>
              <li className='mb-0 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Reforço da imagem de marca empregadora da instituição.
              </li>
            </ul>
          </div>
          <div className='col-12 col-lg-6'>
            <SubHeaderVantagem className='consignado-privado__subheader-vantagem fs-17 fs-lg-36 d-flex align-items-center mb-4 text-white'>
              <span className='d-none d-lg-block'>
                <OrangeIcon icon='user-account' color='#FFFFFF' size='LG' />
              </span>
              <span className='d-lg-none'>
                <OrangeIcon icon='user-account' color='#FFFFFF' size='MD' />
              </span>
              Pro Colaborador
            </SubHeaderVantagem>
            <ul className='list-style-none p-0 my-4 text-white'>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Rapidez em todo processo com desconto direto na folha de pagamento.
              </li>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Condições especiais em outros produtos do Banco Inter pra quem é Cliente Consignado Privado.
              </li>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Dinheiro rápido, fácil e seguro pra fazer o que quiser.
              </li>
              <li className='mb-3 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Oportunidade de contar com empréstimo mais barato que as modalidades tradicionais.
              </li>
              <li className='mb-0 pt-1 d-flex align-items-start fs-16 fs-lg-18 lh-25'>
                <Icon width='23' height='23' directory='v2/action/stats' icon='success' />
                Assessoria especializada para tirar todas as dúvidas.
              </li>
            </ul>
          </div>
        </div>
        <div className='text-center mt-3'>
          <ScrollTo
            to='#seja-conveniado'
            title='Quero pra minha empresa'
            styles='btn btn--lg mb-md-4 btn--lg text-white bg-turquoise rounded-2 text-uppercase'
            section='dobra_04'
            sectionName='Veja as vantagens'
            elementName='Quero pra minha empresa'
          >
            Quero pra minha empresa
          </ScrollTo>
        </div>
      </div>
    </Section>
  )
}

export default Vantagens
