import React from 'react'
import { SubHeader, Section, SejaConveniadoSubtitle, FormContainer } from './style'

import ConsignadoPrivadoForm from 'src/components/UI/Forms/ConsignadoPrivadoForm'

const SejaConveniada = () => {
    return (
      <Section id='seja-conveniado' className='py-5'>
        <div className='container'>
          <SubHeader className='fs-26 fs-lg-40 text-center text-white mb-2'>Seja uma empresa conveniada</SubHeader>
          <SejaConveniadoSubtitle className='mt-0 mb-4 mx-auto'>
            <p className='text-center text-white fs-17'>Solicite ou indique um contato responsável pelo setor de benefícios da sua empresa</p>
          </SejaConveniadoSubtitle>
          <FormContainer className='d-flex flex-column justify-content-center'>
            <ConsignadoPrivadoForm />
          </FormContainer>
        </div>
      </Section>
    )
}

export default SejaConveniada
