import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Images
import consignadoPrivadoParceiroMobile from './../../assets/images/banner-consignado-privado-mobile.png'
import consignadoPrivadoParceiroDesktop from './../../assets/images/banner-consignado-privado-lg.png'

export const ContentHeader = styled.section`
  min-height: calc(100vh - 64px);
  align-items: flex-end;
  background-image: url(${consignadoPrivadoParceiroMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
 
  @media ${device.tablet} {
    align-items: center;
    background-image: url(${consignadoPrivadoParceiroDesktop});
    background-repeat: no-repeat;
  }

  @media ${device.desktopLG} {
    background-position: center center;
    min-height: 100vh;
  }

  .bread-crumb {
    width: 315px;
    padding: 0 5px;
    position: absolute;
    top: 8%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    @media ${device.tablet} {
      width: 100%;
      position: relative;
      top: auto;
    }
  }
`
