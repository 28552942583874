import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Header from './sections/header/_header'
import MaisUmBeneficio from './sections/mais-um-beneficio/_mais-um-beneficio'
import EmprestimoConsignadoPrivado from './sections/emprestimo-consignado-privado/_emprestimo-consignado-privado'
import Vantagens from './sections/vantagens/_vantagens'
import ComoFunciona from './sections/como-funciona/_como-funciona'
import SejaConveniada from './sections/seja-conveniada/_seja-conveniada'
import MaisConhecimento from './sections/mais-conhecimento/_mais-conhecimento'
import BancoParceiro from './sections/banco-parceiro/_banco-parceiro'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

function ConsignadoPrivado () {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header />
        <MaisUmBeneficio />
        <EmprestimoConsignadoPrivado />
        <Vantagens />
        <ComoFunciona />
        <SejaConveniada />
        <MaisConhecimento />
        <BancoParceiro />
      </Layout>
    </Wrapper>
  )
}

export default ConsignadoPrivado
