import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import Img from 'gatsby-image'
import { SubHeader, BoxFuncionamento, FuncionamentoIndex } from './style'
import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const ComoFunciona = () => {
    const data = usePageQuery()
    const [ sendDatalayerEvent ] = useDataLayer()

    return (
      <section className='py-5'>
        <div className='container'>
          <SubHeader className='fs-26 fs-lg-40 text-center mb-3 pb-1'>Entenda como funciona</SubHeader>
          <div className='row'>
            <div className='col-12 col-md-6 mb-5 d-flex flex-column flex-lg-row align-items-center'>
              <FuncionamentoIndex className='text-center rounded-50 fs-26 fs-lg-40 position-absolute align-self-start top-0 d-lg-none'>1</FuncionamentoIndex>
              <div className='flex-shrink-0 mr-lg-5' style={{ width: '200px' }}>
                <Img fluid={data.consignadoSimulacao.fluid} alt='Simulação' />
              </div>
              <BoxFuncionamento>
                <FuncionamentoIndex className='text-center rounded-50 fs-26 fs-lg-40 d-none d-lg-block'>1</FuncionamentoIndex>
                <h4 className='fs-17 fs-lg-24 lh-30 my-2 my-lg-4 text-center text-lg-left'>Simulação</h4>
                <p className='fs-16 lh-20 d-flex align-items-center pt-0 pt-lg-2 text-center text-lg-left'>O seu colaborador faz uma simulação na página do Empréstimo Consignado Privado.</p>
              </BoxFuncionamento>
            </div>
            <div className='col-12 col-md-6 mb-5 d-flex flex-column flex-lg-row align-items-center'>
              <FuncionamentoIndex className='text-center rounded-50 fs-26 fs-lg-40 position-absolute align-self-start top-0 d-lg-none'>2</FuncionamentoIndex>
              <div className='flex-shrink-0 mr-lg-5' style={{ width: '200px' }}>
                <Img fluid={data.consignadoAnalise.fluid} alt='Análise' />
              </div>
              <BoxFuncionamento>
                <FuncionamentoIndex className='text-center rounded-50 fs-26 fs-lg-40 d-none d-lg-block'>2</FuncionamentoIndex>
                <h4 className='fs-17 fs-lg-24 lh-30 my-2 my-lg-4 text-center text-lg-left'>Análise</h4>
                <p className='fs-16 lh-20 d-flex align-items-center pt-0 pt-lg-2 text-center text-lg-left'>O Banco Inter analisa a solicitação do cliente.</p>
              </BoxFuncionamento>
            </div>
            <div className='col-12 col-md-6 mb-5 d-flex flex-column flex-lg-row align-items-center'>
              <FuncionamentoIndex className='text-center rounded-50 fs-26 fs-lg-40 position-absolute align-self-start top-0 d-lg-none'>3</FuncionamentoIndex>
              <div className='flex-shrink-0 mr-lg-5' style={{ width: '200px' }}>
                <Img fluid={data.consignadoPrivadoAssinatura.fluid} alt='Assinatura' />
              </div>
              <BoxFuncionamento>
                <FuncionamentoIndex className='text-center rounded-50 fs-26 fs-lg-40 d-none d-lg-block'>3</FuncionamentoIndex>
                <h4 className='fs-17 fs-lg-24 lh-30 my-2 my-lg-4 text-center text-lg-left'>Assinatura</h4>
                <p className='fs-16 lh-20 d-flex align-items-center pt-0 pt-lg-2 text-center text-lg-left'>Contrato é enviado pra assinatura eletrônica do colaborador e do representante de RH.</p>
              </BoxFuncionamento>
            </div>
            <div className='col-12 col-md-6 mb-5 d-flex flex-column flex-lg-row align-items-center'>
              <FuncionamentoIndex className='text-center rounded-50 fs-26 fs-lg-40 position-absolute align-self-start top-0 d-lg-none'>4</FuncionamentoIndex>
              <div className='flex-shrink-0 mr-lg-5' style={{ width: '200px' }}>
                <Img fluid={data.creditoNaMao.fluid} alt='Crédito na Mão' />
              </div>
              <BoxFuncionamento>
                <FuncionamentoIndex className='text-center rounded-50 fs-26 fs-lg-40 d-none d-lg-block'>4</FuncionamentoIndex>
                <h4 className='fs-17 fs-lg-24 lh-30 my-2 my-lg-4 text-center text-lg-left'>Empréstimo na mão</h4>
                <p className='fs-16 lh-20 d-flex align-items-center pt-0 pt-lg-2 text-center text-lg-left'>
                  Com todos os documentos recebidos, o empréstimo é liberado!
                </p>
              </BoxFuncionamento>
            </div>
          </div>
          <div className='text-center mt-lg-5'>
            <ScrollTo
              to='#seja-conveniado'
              title='Quero pra minha empresa'
              styles='btn btn--lg btn--lg text-white bg-turquoise rounded-2 text-uppercase'
              section='dobra_05'
              sectionName='Entenda como funciona'
              elementName='Quero pra minha empresa'
            >
              Quero pra minha empresa
            </ScrollTo>
          </div>
        </div>
      </section>
    )
}

export default ComoFunciona
