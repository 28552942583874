import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerDesktop: imageSharp(fluid: { originalName: { regex: "/banner-consignado-privado-lg/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerMobile: imageSharp(fluid: { originalName: { regex: "/banner-consignado-privado-mobile/" }}) {
        fluid(maxWidth: 480, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      consignadoSimulacao: imageSharp(fluid: { originalName: { regex: "/simulacao/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      consignadoAnalise: imageSharp(fluid: { originalName: { regex: "/analise/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      consignadoPrivadoAssinatura: imageSharp(fluid: { originalName: { regex: "/consignado-privado-assinatura/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      creditoNaMao: imageSharp(fluid: { originalName: { regex: "/credito-na-mao/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      consignadoPrivadoEquipe: imageSharp(fluid: { originalName: { regex: "/consignado-privado-equipe/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }

    }
  `)

  return pageQuery
}

export default PageQuery
