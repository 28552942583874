import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import { SubHeader, CustomBlock, RoundedBorder } from './style'
import AnimatedChart from 'src/components/AnimatedChart'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const EmprestimoConsignadoPrivado = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='d-lg-none col-12 col-lg-6'>
            <SubHeader className='fs-26 fs-lg-40 mb-3 text-center text-lg-left'>O que é Empréstimo Consignado Privado?</SubHeader>
            <p className='mb-5'>É a melhor opção pra quem está em busca de empréstimo rápido, fácil e mais barato.</p>
          </div>
        </div>
        <div className='row flex-column-reverse flex-lg-row justify-content-between'>
          <div className='col-12 col-lg-6'>
            <div className='d-none d-lg-block'>
              <SubHeader className='fs-26 fs-lg-40 mb-3 text-center text-lg-left'>O que é Empréstimo Consignado Privado?</SubHeader>
              <p className='mb-5'>É a melhor opção pra quem está em busca de empréstimo rápido, fácil e mais barato.</p>
            </div>
            <div className='fs-14 lh-18 d-flex align-items-stretch mb-3'>
              <RoundedBorder className='flex-shrink-0 mr-2' />
              <div>
                <b className='fs-16'>Ágil</b>
                <p className='mb-0'>O colaborador simula e contrata online, e o dinheiro pode ser liberado em até 3 dias.</p>
              </div>
            </div>
            <CustomBlock className='fs-14 lh-18 d-flex align-items-stretch mb-3'>
              <RoundedBorder className='flex-shrink-0 mr-2' />
              <div>
                <b className='fs-16'>Prático</b>
                <p className='mb-0'>O valor das parcelas mensais é descontado automaticamente na folha de pagamento do colaborador.</p>
              </div>
            </CustomBlock>
            <CustomBlock className='fs-14 lh-18 d-flex align-items-stretch mb-3'>
              <RoundedBorder className='flex-shrink-0 mr-2' />
              <div>
                <b className='fs-16'>Barato</b>
                <p className='mb-0'>Os juros são mais baixos que os de outras modalidades, como cartão de crédito, cheque
                  especial e empréstimo pessoal.
                </p>
              </div>
            </CustomBlock>
            <div className='text-center text-lg-left mt-5'>
              <ScrollTo
                to='#seja-conveniado'
                title='Quero pra minha empresa'
                styles='btn btn--lg mb-md-4 btn--lg text-white bg-turquoise rounded-2 text-uppercase'
                section='dobra_03'
                sectionName='O que é Empréstimo Consignado Privado?'
                elementName='Quero pra minha empresa'
              >
                Quero pra minha empresa
              </ScrollTo>
            </div>
          </div>
          <div className='col-12 col-lg-5 mb-5'>
            <AnimatedChart id='consignado-privado' />
          </div>
        </div>
      </div>
    </section>
 )
}

export default EmprestimoConsignadoPrivado
