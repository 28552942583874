import React from 'react';

import Success from '../../../../../assets/images/temps/sucesso-pj.png';

type submittedMessagesProps = {
  message: {
    success_text: string;
    success: string;
    previous_page: string;
  };
  onCloseClick: () => {};
}

const createMarkup = (msg: string) => ({ __html: msg })

function EnterpriseSubmittedMessage ({ message, onCloseClick }: submittedMessagesProps) {
  return (
    <div className='success-container bg-white text-center'>
      <div className='h-100 d-flex align-items-center'>
        <div className='box-success text-white pt-lg-5'>
          <p className='text-white fs-22 lh-25 fs-lg-40 lh-lg-45 fw-300 fw-lg-600 mb-4'>{message.success}</p>
          <img src={Success} alt='Imagem de sucesso' />
          <div className='col-md-9 col-lg-11 mx-auto'>
            <p dangerouslySetInnerHTML={createMarkup(message.success_text)} className='text-white lh-25 pt-3' />
            <a href='#' onClick={onCloseClick} className='fs-13 btn-link'>{message.previous_page}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

EnterpriseSubmittedMessage.defaultProps = {
  message: {},
  onCloseClick: () => Object,
}

export default EnterpriseSubmittedMessage
