import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper'
import { SubHeader, Section, OpenVideoContainer } from './style'
import OpenVideo from 'src/components/OpenVideo'

// assets
import playVideo from './../../../../../assets/images/shared/play-video.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const BancoParceiro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-6 text-center text-lg-left'>
            <SubHeader className='fs-26 fs-lg-40 text-white mb-3 pb-1'>Um banco parceiro de verdade</SubHeader>
          </div>
        </div>
        <div className='row flex-column-reverse flex-lg-row align-items-center'>
          <div className='col-12 col-lg-6 text-center text-lg-left'>
            <p className='text-white'>O Banco Inter é o <b>1º banco 100% digital do país</b> e o único a oferecer uma conta totalmente isenta de tarifas.</p>
            <p className='text-white'>Acreditamos mesmo na parceria e que a relação das pessoas com <b>seu banco pode ser muito mais simples, transparente e justa.</b> E estamos fazendo essa transformação acontecer, junto com <b>mais de 4 milhões de correntistas</b>.</p>
            <p className='text-white'>Além da Conta Digital PF, temos também a Conta Digital PJ – <b>totalmente gratuita e ideal pra sua empresa</b>. Movimente o dia a dia bancário do seu negócio sem nenhuma tarifa e resolva tudo pelo app e Internet Banking.</p>
            <Link
              to='/empresas/conta-digital/pessoa-juridica/'
              className='btn btn--lg text-white bg-turquoise rounded-2 mt-3 text-uppercase'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: 'Um banco parceiro de verdade',
                  element_action: 'click button',
                  element_name: 'Conheça a conta pj',
                  redirect_url: 'https://inter.co/empresas/conta-digital/pessoa-juridica/',
                })
              }}
            >
              Conheça a conta pj
            </Link>
          </div>
          <div className='col-12 col-lg-6 py-5 py-lg-0 mb-3 d-flex justify-content-center'>
            <OpenVideoContainer>
              <OpenVideo
                link='https://www.youtube.com/embed/FhagIQGobR0'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_08',
                    section_name: 'Um banco parceiro de verdade',
                    element_action: 'click video',
                    element_name: 'Clique pra assistir o vídeo',
                    redirect_url: 'https://www.youtube.com/embed/FhagIQGobR0',
                  })
                }}
              >
                <div className='d-flex flex-column align-items-center'>
                  <img src={playVideo} alt='Assistir Vídeo' width='62' height='62' className='mb-1' />
                  <small className='text-white'>Clique pra assistir o vídeo</small>
                </div>
              </OpenVideo>
            </OpenVideoContainer>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default BancoParceiro
