import styled from 'styled-components'
import { blueMedium } from 'src/styles/colors'

export const SubHeader = styled.h2`
  line-height: 1.2em;
  color: ${blueMedium};
`
export const CustomBlock = styled.div`
  b {
    color: #6A6E81;
  }
`
