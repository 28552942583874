import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from './../../pageQuery'

const MaisConhecimento = () => {
 const data = usePageQuery()
 return (
   <section className='py-5'>
     <div className='container'>
       <div className='row'>
         <div className='col-12 col-lg-6 mb-0 text-center text-lg-left'>
           <h3 className='fs-26 fs-lg-40 mb-lg-0'>Mais conhecimento para sua equipe</h3>
         </div>
       </div>
       <div className='row flex-column-reverse flex-lg-row align-items-lg-end'>
         <div className='col-12 col-lg-7 text-center text-lg-left mb-lg-4'>
           <p>Nosso objetivo não é apenas ofertar uma linha de crédito especial, mas também um programa de <b>Educação Financeira para os colaboradores da sua empresa</b>.</p>
           <p>Temos o desafio de mudar a relação Cliente x Banco através da <b>disseminação de conteúdo e produtos justos e coerentes com as condições de cada indivíduo</b>.</p>
           <p className='mb-0'>Lembrando que todas as definições do público, frequência, tipos de ações e as premissas básicas para a contratação do Empréstimo Consignado pelo colaborador são definidas de acordo com as demandas e orientações da sua empresa.</p>
         </div>
         <div className='col-12 col-md-8 col-lg-5 mx-auto py-3 py-lg-0'>
           <Img fluid={data.consignadoPrivadoEquipe.fluid} alt='Sua equipe informada' />
         </div>
       </div>
     </div>
   </section>
 )
}

export default MaisConhecimento
