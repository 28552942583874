import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { blueMedium, white } from 'src/styles/colors'

// img
import consginadoPrivadoFormBg from './../../assets/images/consignado-privado-form-bg.png'

export const Section = styled.section`
  min-height: 600px;
  background: url(${consginadoPrivadoFormBg}) no-repeat center center;
  background-size: cover;
`
export const SubHeader = styled.h2`
  line-height: 1.2em;
  color: ${blueMedium};
`
export const SejaConveniadoSubtitle = styled.div`
  max-width: 500px;
`
export const FormContainer = styled.div`
  .form-section {
    padding: 0 !important;
    max-width: 1000px;
    margin: 0 auto;
  }
  .form-section--background .custom-form {
    padding: 0;

    box-shadow: none !important;
    label {
      color: ${white};
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
    }

    .inter-forms--input {
      cursor: text;
      height: 40px;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      color: #8A96A8;
    }

    .inter-forms--select {
      height: 40px;
    }
    .inter-forms--select select {
      height: 37px;
      font-size: 14px;
      color: #8A96A8;
    }

    .error {
      color: white;
    }

    .success-container {
      background: transparent !important;
      color: white;
    }

    @media ${device.tablet} {
      .col-12:nth-child(3),
      .col-12:nth-child(4) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    .icon--app-store-mono-pt,
    .icon--play-store-mono-pt {
      fill: white !important;
    }
  }
`
