import React from 'react'
import ScrollTo from 'src/components/ScrollTo'
import { SubHeader, CustomBlock } from './style'
import Icon from 'src/components/UI/MarkdownIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const MaisUmBeneficio = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='beneficios' className='bg-grayscale--100 py-5'>
      <div className='container'>
        <SubHeader className='fs-26 fs-lg-40 text-center mb-3'>Por que oferecer mais um benefício?</SubHeader>
        <p className='text-center mx-auto' style={{ maxWidth: '900px' }}> A oferta de benefícios para os colaboradores, quando bem planejada e desenvolvida, é altamente positiva pra empresa e ajuda a alcançar metas que fazem bem pra saúde da organização.</p>
        <div className='row my-5'>
          <div className='col-12 col-md-6 col-lg-4 mb-3'>
            <CustomBlock className='p-3 fs-16 bg-white rounded-4 h-100 p-3'>
              <div className='mb-2'>
                <Icon width='30' height='30' directory='v2/products/investimentos' icon='porquinho' />
              </div>
              <b className='fs-17'>Esteja ao lado do seu time</b><br />
              <p>Oportunidade pra auxiliar diretamente na vida financeira dos colaboradores, sobretudo em situações emergenciais.</p>
            </CustomBlock>
          </div>
          <div className='col-12 col-md-6 col-lg-4 mb-3'>
            <CustomBlock className='fs-16 bg-white rounded-4 h-100 p-3'>
              <div className='mb-2'>
                <Icon width='30' height='30' directory='v2/misc' icon='smile' />
              </div>
              <b className='fs-17'>Valorização</b><br />
              <p>Possibilidade de reconhecer ainda mais o trabalho da equipe e atingir a expectativa dos colaboradores.</p>
            </CustomBlock>
          </div>
          <div className='col-12 col-md-6 col-lg-4 mb-3'>
            <CustomBlock className='fs-16 bg-white rounded-4 h-100 p-3'>
              <div className='mb-2'>
                <Icon width='30' height='30' directory='v2/products/investimentos/renda-variavel' icon='maiores-baixas' />
              </div>
              <b className='fs-17'>Retenção</b><br />
              <p>Redução do turnover nas organizações, além de ser um diferencial competitivo para atrair novos talentos.</p>
            </CustomBlock>
          </div>
          <div className='col-12 col-md-6 col-lg-4 mb-3'>
            <CustomBlock className='fs-16 bg-white rounded-4 h-100 p-3'>
              <div className='mb-2'>
                <Icon width='30' height='30' directory='v2/products/investimentos' icon='renda-fixa' />
              </div>
              <b className='fs-17'>Engajamento</b><br />
              <p>Aumenta o envolvimento e a produtividade do time, já que os colaboradores se sentem mais motivados na organização.</p>
            </CustomBlock>
          </div>
          <div className='col-12 col-md-6 col-lg-4 mb-3'>
            <CustomBlock className='fs-16 bg-white rounded-4 h-100 p-3'>
              <div className='mb-2'>
                <Icon width='30' height='30' directory='v2/social' icon='heart' />
              </div>
              <b className='fs-17'>Imagem institucional</b><br />
              <p>Reforça a cultura e a imagem da organização, atraindo e retendo talentos, além de se destacar no mercado.</p>
            </CustomBlock>
          </div>
          <div className='col-12 col-md-6 col-lg-4 mb-3'>
            <CustomBlock className='fs-16 bg-white rounded-4 h-100 p-3'>
              <div className='mb-2'>
                <Icon width='30' height='30' directory='v2/products' icon='super-app' />
              </div>
              <b className='fs-17'>Conta Digital grátis</b><br />
              <p>Seu time também pode ter uma Conta Digital totalmente completa e gratuita, com serviços e benefícios que só o Inter oferece.</p>
            </CustomBlock>
          </div>
        </div>
        <p className='text-center mx-auto' style={{ maxWidth: '900px' }}>
          Disponibilizar para os seus colaboradores o Empréstimo Consignado Privado <b>não gera custo nenhum</b> pra sua empresa e pode contribuir para <b>aumentar a motivação. Demonstre preocupação com a qualidade de vida da sua equipe!</b>
        </p>
        <div className='text-center mt-4'>
          <ScrollTo
            to='#seja-conveniado'
            title='Quero pra minha empresa'
            styles='btn btn--lg mb-md-4 btn--lg text-white bg-turquoise rounded-2 text-uppercase'
            section='dobra_02'
            sectionName='Por que oferecer mais um benefício?'
            elementName='Quero pra minha empresa'
          >
            Quero pra minha empresa
          </ScrollTo>
        </div>
      </div>
    </section>
  )
}

export default MaisUmBeneficio
