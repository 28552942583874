import styled from 'styled-components'
import { blueMedium } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

import consignadoPrivadoParceiroMobile from './../../assets/images/consignado-privado-parceiro-mobile.png'
import consignadoPrivadoParceiroDesktop from './../../assets/images/consignado-privado-parceiro.png'

export const Section = styled.section`
  min-height: 570px;
  background: url(${consignadoPrivadoParceiroMobile}) top center;
  background-size: cover;

  @media ${device.desktopLG} {
    background: url(${consignadoPrivadoParceiroDesktop}) no-repeat top center;
    background-size: cover;
  }
`

export const SubHeader = styled.h2`
  line-height: 1.2em;
  color: ${blueMedium};
`
export const OpenVideoContainer = styled.div`
  cursor: pointer;
  max-width: 100%;

  @media ${device.desktopLG} {
    max-width: 180px;
  }
`
