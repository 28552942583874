import styled from 'styled-components'
import { blueMedium } from 'src/styles/colors'

export const Section = styled.section`
  background: linear-gradient(24.33deg, #194A69 0%, #072534 100%);
  ul > li {
   svg {
    flex-shrink: 0;
    margin-right: 5px;
    margin-top: 2px;
    width: 19px !important;
    height: 19px !important;
   }
  }
`
export const SubHeader = styled.h2`
  line-height: 1.2em;
  color: ${blueMedium};
`
export const SubHeaderVantagem = styled.h4`
  svg {
    margin-right: 10px;
  }
`
