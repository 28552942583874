import React from 'react'
import BreadCrumb from 'src/components/Breadcrumb'
import ScrollTo from 'src/components/ScrollTo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ContentHeader } from './style'

const Header = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

    return (
      <ContentHeader className='position-relative d-flex'>
        <div className='container'>
          <div className='row flex-column justify-content-between justify-content-lg-center'>
            <BreadCrumb
              sectionName='Faça parte das conquistas da sua equipe'
              type='Empresas'
              text='Conheça o Empréstimo Consignado Privado'
              link='/empresas/consignado-privado'
              styles='d-md-none text-white'
            />
            <div className='col-12 d-none d-md-block mt-5 mt-lg-0 pt-md-5 pt-lg-0 pl-xl-3'>
              <BreadCrumb
                sectionName='Faça parte das conquistas da sua equipe'
                type='Empresas'
                text='Conheça o Empréstimo Consignado Privado'
                link='/empresas/consignado-privado'
                styles='mt-md-5 pb-3 px-lg-0'
              />
            </div>
            <div className='col-12 col-lg-6 pr-lg-0 pr-xl-3'>
              <h1 className='fs-22 fs-md-50 mb-3 fw-600 text-white'>
                Faça parte das conquistas da sua equipe
              </h1>
              <p className='fs-17 text-white mb-3 pr-lg-3 pr-xl-0'>Engaje todo seu time com um <b>benefício totalmente sem custos.</b>
                <br className='d-none d-lg-inline' /> Ofereça empréstimo mais barato e conhecimento sobre educação financeira.
              </p>
            </div>
            <div className='col-12 mt-4 mb-4 mb-md-0 pr-lg-0'>
              <ScrollTo
                to='#beneficios'
                title='Conheça os benefícios'
                styles='btn btn--lg mb-4 mb-md-0 btn--lg text-white bg-turquoise rounded-2 text-uppercase'
                section='dobra_01'
                sectionName='Faça parte das conquistas da sua equipe'
                elementName='Conheça os benefícios'
              >
                Conheça os benefícios
              </ScrollTo>
            </div>
          </div>
        </div>
      </ContentHeader>
    )
}

export default Header
