import styled from 'styled-components'
import { blueMedium } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SubHeader = styled.h2`
  line-height: 1.2em;
  color: ${blueMedium};
`
export const BoxFuncionamento = styled.div`
  h4 {
    color: #4A4A4A;
  }
`
export const FuncionamentoIndex = styled.span`
  display: block;
  text-align: center;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(45deg, #194A69 0%, #072534 100%);
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  -webkit-text-stroke: 1px white;

  @media ${device.desktopLG} {
    width: 60px;
    height: 60px;
    line-height: 60px;
    -webkit-text-stroke: 1.5px white;
  }
`
